class ZIDXAccountLandingPages implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountLandingPagesContainer";
    }
    getMatchURL(){
        return "/account/landing-pages";
    }
    render(){
        // console.log("landing pages");
    }
}